import inflection from 'inflection';
import { ProfessionalAppStatusEnum } from 'typings/enums';
import { ENUMS } from './enums';
import { revenueAdjustmentNameMap } from '@medely/base';

const nameMap: Map<string, string> = new Map([
  [`Job.status.${ENUMS.Job.status.late_cancellation}`, 'Canceled by staff'],
  [`Professional.application_status.${ProfessionalAppStatusEnum.q_and_a}`, 'Q and A'],
  [
    `Invoice.voucher_processing_status.${ENUMS.Invoice.voucher_processing_status.missing_timesheet}`,
    'Missing Time Sheet/Time Sheet Issue',
  ],
  [
    `Invoice.voucher_processing_status.${ENUMS.Invoice.voucher_processing_status.biweekly}`,
    'BiWeekly (Not this week)',
  ],
  [
    `ExpenseAdjustment.taxable_type.${ENUMS.ExpenseAdjustment.taxable_type.MEAL_PENALTY_FEE}`,
    'Meal Penalty',
  ],
  [`FinancialTransaction.kind.${ENUMS.FinancialTransaction.kind.stripe_ach_in_app}`, 'In-app ACH'],
  ...revenueAdjustmentNameMap,

  // remove after PR 3323 is merged
  [
    `RevenueAdjustment.adjustment_type.${ENUMS.RevenueAdjustment.adjustment_type.TRUST_AND_SAFETY_FEE_CREDIT}`,
    'Trust & safety fee credit',
  ],

  [
    `RevenueAdjustment.adjustment_type.${ENUMS.RevenueAdjustment.adjustment_type.LX_NS_CREDIT}`,
    'Pro No-Show/Late Cancellation Credit',
  ],
]);

export const getName = (resource, source, { id, name }) => {
  const key = `${resource}.${source}.${id}`;
  return nameMap.get(key) ?? inflection.humanize(name);
};
