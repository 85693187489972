import React from 'react';
import { gql } from '@apollo/client';
import { Show } from 'react-admin';
import RateSettingsShow from '../RateSettingsShow';

const StateLocationTypeRateSettingShow = () => (
  <Show>
    <RateSettingsShow />
  </Show>
);

StateLocationTypeRateSettingShow.query = gql`
  query stateLocationTypeRateSetting($id: Int!) {
    stateLocationTypeRateSetting(id: $id, include_parents: true) {
      id
      active
      created_at
      location_type_id
      settings
      state_id
      updated_at
    }
  }
`;

export default StateLocationTypeRateSettingShow;
