import React from 'react';
import {
  Datagrid,
  TextField,
  FunctionField,
  NumberField,
  BooleanInput,
  useResourceContext,
  useRecordContext,
  SimpleShowLayout,
  BooleanField,
} from 'react-admin';
import LinkField from 'components/LinkField';
import { Button } from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import MoneyField from 'components/MoneyField';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import ProRateField from 'components/ProRateField';
import { camelToSnakeCase, DisplayHelpers } from 'utils/helpers';
import { buildCustomExporter } from 'resources/rate_settings/buildCustomExporter';
import { calcTotalPay } from 'resources/rate_settings/rateSettingsCalculations';
import List from 'components/List';
import { Box } from '@mui/system';
import { RateSettingsDisplayHelper } from 'utils/displayHelpers/rate-settings';

const Filters = () => {
  const record = useRecordContext();
  return [
    <PositionInput
      alwaysOn
      source="search.position_ids"
      multiple
      clearable
      filter={{ search: { position_ids: record.position_ids } }}
    />,
    <SpecialtyInput
      alwaysOn
      multiple
      clearable
      source="search.specialty_ids"
      positionSource="search.position_ids"
      optionText="label"
    />,
    <BooleanInput alwaysOn label="Valid For Jobs" source="for_job" />,
    <BooleanInput alwaysOn label="Valid For Assignments" source="for_assignment" />,
  ];
};

const getExporter = (filenamePrefix) => (rateSettings) => {
  if (!rateSettings || rateSettings.length === 0) {
    return true;
  }

  return buildCustomExporter(rateSettings, filenamePrefix);
};

const CreatePositionRateSettingButton = () => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const recordId = record[`${camelToSnakeCase(resource)}_id`];
  const to = recordId ? `/${resource}/${recordId}/edit` : `/${resource}/create`;
  let display;
  if (record.location_position_rate_setting_id && record.location_id) {
    display = 'Update';
  } else if (record.market_position_rate_setting_id && record.market_id) {
    display = 'Update';
  } else if (record.state_position_rate_setting_id && record.state_id) {
    display = 'Update';
  } else {
    display = 'Create Custom';
  }
  return (
    <FunctionField
      render={() => (
        <Button component={Link} size="small" color="primary" to={to} state={{ record }}>
          {display}
        </Button>
      )}
    />
  );
};

const PayPositionRateSettingsTab = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const parentIdName = `${camelToSnakeCase(resource)}_id`;
  const resourceName = `${resource}PositionRateSetting`;
  const exporter = getExporter(`${record.__typename} ${record.id}`);

  function getChargeRateCents(settings) {
    const rateSettingsDisplayHelper = new RateSettingsDisplayHelper(settings);

    return rateSettingsDisplayHelper.getChargeRateCents();
  }

  return (
    <SimpleShowLayout>
      <Box display="flex">
        <Button
          component={Link}
          color="primary"
          to={`/${resourceName}/create`}
          state={{ record: { [parentIdName]: record.id } }}
        >
          Add position rate settings
        </Button>
      </Box>
      <List
        resource={resourceName}
        filters={Filters()}
        hasCreate={false}
        filter={{
          [parentIdName]: record.id,
          include_parents: true,
          position_ids: record.position_ids,
        }}
        exporter={exporter}
        disableSyncWithLocation
      >
        <Datagrid bulkActionButtons={false}>
          <CreatePositionRateSettingButton />
          <FunctionField
            label="Active for Job"
            render={(v) => (v?.settings.can_post_job ? <Check /> : <Clear />)}
          />
          <FunctionField
            label="Active for Assignment"
            render={(v) => (v?.settings.can_post_assignment ? <Check /> : <Clear />)}
          />
          <LinkField source={parentIdName} />
          <TextField label="Position" source="position.display_name" />
          <TextField label="Specialty" source="specialty.label" />
          {resource === 'Market' && <TextField label="Market" source="market.display_name" />}
          <ProRateField label="1099 Pro Rate" />
          <FunctionField
            label="Pro Blended 12hr Rate"
            render={({ settings }) =>
              DisplayHelpers.money(
                calcTotalPay(
                  getChargeRateCents(settings),
                  settings.margin_percent_1099,
                  12,
                  settings.ten99_per_diem.charge_max_daily_regular_hours,
                  settings.ten99_per_diem.charge_max_daily_overtime_hours,
                  settings.ten99_per_diem.charge_overtime_multiplier,
                  settings.ten99_per_diem.payout_max_daily_regular_hours,
                  settings.ten99_per_diem.payout_max_daily_overtime_hours,
                  settings.ten99_per_diem.payout_overtime_multiplier,
                ) / 12,
              )
            }
          />
          <FunctionField
            label="Pro 12hr Total"
            render={({ settings }) =>
              DisplayHelpers.money(
                calcTotalPay(
                  getChargeRateCents(settings),
                  settings.margin_percent_1099,
                  12,
                  settings.ten99_per_diem.charge_max_daily_regular_hours,
                  settings.ten99_per_diem.charge_max_daily_overtime_hours,
                  settings.ten99_per_diem.charge_overtime_multiplier,
                  settings.ten99_per_diem.payout_max_daily_regular_hours,
                  settings.ten99_per_diem.payout_max_daily_overtime_hours,
                  settings.ten99_per_diem.payout_overtime_multiplier,
                ),
              )
            }
          />
          <FunctionField
            label="Pro 8hr w/ Lunch Total"
            render={({ settings }) =>
              DisplayHelpers.money(
                calcTotalPay(
                  getChargeRateCents(settings),
                  settings.margin_percent_1099,
                  8,
                  settings.ten99_per_diem.charge_max_daily_regular_hours,
                  settings.ten99_per_diem.charge_max_daily_overtime_hours,
                  settings.ten99_per_diem.charge_overtime_multiplier,
                  settings.ten99_per_diem.payout_max_daily_regular_hours,
                  settings.ten99_per_diem.payout_max_daily_overtime_hours,
                  settings.ten99_per_diem.payout_overtime_multiplier,
                ),
              )
            }
          />
          <FunctionField
            label="Pro 8hr w/o Lunch Total"
            render={({ settings }) =>
              DisplayHelpers.money(
                calcTotalPay(
                  getChargeRateCents(settings),
                  settings.margin_percent_1099,
                  7.5,
                  settings.ten99_per_diem.charge_max_daily_regular_hours,
                  settings.ten99_per_diem.charge_max_daily_overtime_hours,
                  settings.ten99_per_diem.charge_overtime_multiplier,
                  settings.ten99_per_diem.payout_max_daily_regular_hours,
                  settings.ten99_per_diem.payout_max_daily_overtime_hours,
                  settings.ten99_per_diem.payout_overtime_multiplier,
                ),
              )
            }
          />
          <NumberField
            label="1099 Payout Extended Rate"
            source="settings.ten99_per_diem.payout_overtime_multiplier"
          />
          <NumberField
            label="1099 Payout Second Extended Rate"
            source="settings.ten99_per_diem.payout_double_overtime_multiplier"
          />
          <NumberField
            label="1099 Payout Extended Rate Starts At"
            source="settings.ten99_per_diem.payout_max_daily_regular_hours"
          />
          <FunctionField
            label="1099 Payout Second Extended Rate Starts At"
            render={({ settings }) =>
              DisplayHelpers.roundToTwoDecimals(
                settings.ten99_per_diem.payout_max_daily_regular_hours +
                  settings.ten99_per_diem.payout_max_daily_overtime_hours,
              )
            }
          />
          <NumberField
            label="1099 Payout Weekly Extended Rate Starts At"
            source="settings.ten99_per_diem.payout_max_weekly_regular_hours"
          />
          <BooleanField
            label="1099 Payout Seventh Day Rule Enabled"
            source="settings.ten99_per_diem.payout_seventh_day_rule_enabled"
          />
          <MoneyField
            label="Assignment Bonus Tier 2 Travel Amount"
            source="settings.assignment_bonus_tier2_travel_amount_cents"
          />
          <MoneyField
            label="Assignment Bonus Tier 2 travel Amount TCV"
            source="settings.assignment_bonus_tier2_travel_amount_tcv_cents"
          />
          <MoneyField
            label="Assignment Bonus Tier 1 Travel Amount"
            source="settings.assignment_bonus_tier1_travel_amount_cents"
          />
          <MoneyField
            label="Assignment Bonus Tier 1 Travel Amount TCV"
            source="settings.assignment_bonus_tier1_travel_amount_tcv_cents"
          />
          <TextField label="Parent" source="id" />
        </Datagrid>
      </List>
    </SimpleShowLayout>
  );
};

export default PayPositionRateSettingsTab;
