import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import {
  BooleanField,
  Datagrid,
  BooleanInput,
  DateInput,
  FunctionField,
  NumberField,
} from 'react-admin';
import List from 'components/List';
import PositionInput from 'components/PositionInput';
import StateInput from 'components/StateInput';
import LinkField from 'components/LinkField';
import MoneyField from 'components/MoneyField';
import DateTimeField from 'components/DateTimeField';

const StatePositionRateSettingListFilters = [
  <IdInput />,
  <PositionInput emptyText="All" alwaysOn />,
  <StateInput label="State" source="state_id" alwaysOn resettable />,
  <BooleanInput alwaysOn source="active" />,
  <DateInput alwaysOn label="Updated after" source="search.updated_at_from" />,
];

const StatePositionRateSettingList = () => (
  <List filters={StatePositionRateSettingListFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <LinkField label="ID" source="id" />
      <LinkField label="State" source="state.name" />
      <LinkField label="Position" source="position.display_name" />
      <LinkField label="Specialty" source="specialty.label" />
      <MoneyField
        label="Charge 8hr rate"
        source="settings.charge_rate_cents"
        blankIfNull={true}
        sortBy="charge_rate_cents"
      />
      <NumberField
        label="Rate multiplier"
        source="settings.blended_rate_multiplier"
        sortBy="blended_rate_multiplier"
      />
      <FunctionField
        label="Charge 12h rate"
        render={({ settings }) => {
          const blendedRate = settings.charge_rate_cents * settings.blended_rate_multiplier;
          return (blendedRate / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        }}
      />
      <BooleanField source="active" />
      <DateTimeField format="MM/dd/yyyy HH:mm" source="updated_at" timeZone="America/Los_Angeles" />
    </Datagrid>
  </List>
);

StatePositionRateSettingList.query = gql`
  query statePositionRateSettings($input: StatePositionRateSettingQueryInput) {
    _statePositionRateSettingsMeta(input: $input) {
      count
    }
    statePositionRateSettings(input: $input) {
      id
      active
      position {
        id
        display_name
      }
      position_id
      settings {
        assignment_bonus_tier1_travel_amount_cents
        assignment_bonus_tier1_travel_amount_tcv_cents
        assignment_bonus_tier2_travel_amount_cents
        assignment_bonus_tier2_travel_amount_tcv_cents
        blended_rate_cents
        blended_rate_multiplier
        can_post_assignment
        can_post_job
        charge_on_call_hourly_rate_cents
        charge_on_call_hourly_rate_currency
        charge_overtime_multiplier
        charge_rate_cents
        fee_percent
        margin_percent_1099
        margin_percent_w2_local
        margin_percent_w2_tcv
        margin_percent_w2_travel
        margin_w2_tcv_cents
        on_call_fee_percent
        original_charge_rate_cents
        payout_on_call_hourly_rate_cents
        payout_on_call_hourly_rate_currency
        payout_overtime_multiplier
        ten99_assignment
        ten99_per_diem
        w2
        w2_fee_percent
      }
      specialty {
        id
        label
      }
      specialty_id
      state {
        id
        name
      }
      state_id
      state_position_rate_setting_active
      state_position_rate_setting_id
      updated_at
    }
  }
`;

export default StatePositionRateSettingList;
