import React from 'react';
import {
  TextField,
  BooleanField,
  NumberField,
  SimpleShowLayout,
  useRecordContext,
  useResourceContext,
  DateField,
  Labeled,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import ProRateField from 'components/ProRateField';
import LinkField from 'components/LinkField';
import PercentField from 'components/PercentField';
import W2And1099SubSettingsShow from './W2And1099SubSettingsShow';
import TimestampFields from 'components/TimestampFields';
import RateAudits from './RateAudits';
import { RateSettingsDisplayHelper } from 'utils/displayHelpers/rate-settings';

const getLevelPrefix = (resource: string) => {
  if (resource === 'LocationPositionRateSetting') {
    return 'location';
  } else if (resource === 'MarketPositionRateSetting') {
    return 'market';
  } else if (resource === 'StatePositionRateSetting') {
    return 'state';
  }
  return '';
};

const showPostAndAgencyRatesList = [
  'LocationPositionRateSetting',
  'MarketPositionRateSetting',
  'AgencyLocationPositionRateSetting',
];
const showCanPostList = [...showPostAndAgencyRatesList, 'StatePositionRateSetting'];

const activeRateLabel = '(Active)';

const PositionRateSettingsShow = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  if (!record) {
    return null;
  }
  const { user_id, market_id, state_id, settings: inheritedSettings, market, state } = record;
  const settingsField =
    resource === 'AgencyLocationPositionRateSetting'
      ? 'settings'
      : `${getLevelPrefix(resource)}_position_rate_setting`;

  const settings = record[settingsField];
  if (!settings) {
    // react-admin optimistic rendering will cause this to be null for a second when navigating from the list view.
    return null;
  }

  const showPostAndAgencyRates = showPostAndAgencyRatesList.includes(resource);
  const showCanPost = showCanPostList.includes(resource);

  function shouldPutActiveLabel(rate: '8-hour-rate' | 'blended-rate') {
    const rateSettingsDisplayHelper = new RateSettingsDisplayHelper(record.settings);

    try {
      const activeRate = rateSettingsDisplayHelper.getActiveRate();

      return activeRate === rate;
    } catch {
      return false;
    }
  }

  return (
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      {market_id && <LinkField source="market_id" />}
      {market && <TextField label="Market Display" source="market.display_name" />}
      {user_id && <LinkField source="user_id" />}
      {state_id && <LinkField source="state_id" />}
      {state && <TextField label="State" source="state.name" />}
      <TextField label="Position Name" source="position.display_name" />
      <LinkField label="Location ID" source="location_id" />
      <LinkField label="Position ID" source="position_id" />
      <TextField label="Specialty ID" source="specialty_id" />
      <LinkField label="Specialty Name" source="specialty.label" />
      <BooleanField source="active" />
      {showCanPost && (
        <>
          <Labeled fullWidth label="Active for per-diem shifts">
            <BooleanField source={`${settingsField}.can_post_job`} />
          </Labeled>
          <Labeled fullWidth label="Active for assignments">
            <BooleanField source={`${settingsField}.can_post_assignment`} />
          </Labeled>
        </>
      )}
      {market && <DateField label="Last Review Date" source="last_rate_review" />}

      <NumberField label="Location Rate" />
      {settings.charge_rate_minimum_cents && (
        <MoneyField
          label="Charge Rate Minimum"
          source={`${settingsField}.charge_rate_minimum_cents`}
        />
      )}
      {inheritedSettings.charge_rate_cents &&
      inheritedSettings.margin_percent_1099 &&
      inheritedSettings.ten99_per_diem ? (
        <ProRateField />
      ) : (
        <NumberField label="Professional Rate" />
      )}
      {settings.charge_on_call_hourly_rate_cents ? (
        <MoneyField
          label="Location On Call Rate"
          source={`${settingsField}.charge_on_call_hourly_rate_cents`}
        />
      ) : (
        <NumberField label="Location On Call Rate" />
      )}
      <PercentField
        label="On Call Percentage Fee"
        source={`${settingsField}.on_call_fee_percent`}
        record={record}
      />
      <PercentField label="Margin 1099 Percent" source={`${settingsField}.margin_percent_1099`} />
      <PercentField
        label="Margin W2 Local Percent"
        source={`${settingsField}.margin_percent_w2_local`}
      />
      <PercentField
        label="Margin W2 Travel Percent"
        source={`${settingsField}.margin_percent_w2_travel`}
      />
      <PercentField
        label="Margin W2 TCV Percent"
        source={`${settingsField}.margin_percent_w2_tcv`}
      />
      <MoneyField label="Margin W2 TCV" source={`${settingsField}.margin_w2_tcv_cents`} />
      <MoneyField
        label="Assignment Bonus Tier 2 Travel Amount"
        source={`${settingsField}.assignment_bonus_tier2_travel_amount_cents`}
      />
      <MoneyField
        label="Assignment Bonus Tier 2 Travel Amount TCV"
        source={`${settingsField}.assignment_bonus_tier2_travel_amount_tcv_cents`}
      />
      <MoneyField
        label="Assignment Bonus Tier 1 Travel Amount"
        source={`${settingsField}.assignment_bonus_tier1_travel_amount_cents`}
      />
      <MoneyField
        label="Assignment Bonus Tier 1 Travel Amount TCV"
        source={`${settingsField}.assignment_bonus_tier1_travel_amount_tcv_cents`}
      />
      {showPostAndAgencyRates && (
        <MoneyField label="Agency Charge Rate" source="settings.agency_charge_rate_cents" />
      )}
      <BooleanField label="Using 12h location rate structure" source="settings.use_blended_rate" />
      <MoneyField
        label={`8 hour rate ${shouldPutActiveLabel('8-hour-rate') ? activeRateLabel : ''}`}
        source={'settings.original_charge_rate_cents'}
      />
      <MoneyField
        label={`12 hour rate ${shouldPutActiveLabel('blended-rate') ? activeRateLabel : ''}`}
        source={'settings.blended_rate_cents'}
      />
      <NumberField label="12h rate markup" source={'settings.blended_rate_multiplier'} />

      <TimestampFields />
      {resource !== 'StatePositionRateSetting' && (
        <W2And1099SubSettingsShow source={settingsField} />
      )}
      <RateAudits />
    </SimpleShowLayout>
  );
};

export default PositionRateSettingsShow;
