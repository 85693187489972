import React from 'react';
import { Button } from '@mui/material';
import useRequestCsv from 'hooks/useRequestCsv';

export const BulkTestUploadButtons = () => {
  const downloadCSV = useRequestCsv();

  const showButton =
    process.env.REACT_APP_STAGING === 'true' || process.env.NODE_ENV === 'development';

  if (!showButton) {
    return null;
  }
  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={() =>
          downloadCSV({
            url: `v3/admin/development/jobs/download_import_template`,
            filename: `medely_test_jobs_import.csv`,
          })
        }
      >
        Download Import Template
      </Button>
    </>
  );
};
