import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput, useResourceContext } from 'react-admin';

const LocationTypeInput = (props) => {
  const resource = useResourceContext(props);

  return (
    <ReferenceInput resource={resource} {...props}>
      <AutocompleteInput optionText="label" {...getChildrenProps(props)} />
    </ReferenceInput>
  );
};

LocationTypeInput.defaultProps = {
  label: 'Location Type',
  source: 'location_type_id',
  reference: 'LocationType',
  sort: { field: 'label', order: 'ASC' },
  perPage: 60,
};

export default LocationTypeInput;
