import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { BooleanField, Datagrid, BooleanInput, DateInput } from 'react-admin';
import List from 'components/List';
import StateInput from 'components/StateInput';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';
import LocationTypeInput from 'components/LocationTypeInput';

const StateLocationTypeRateSettingListFilters = [
  <IdInput />,
  <StateInput label="State" source="state_id" alwaysOn resettable />,
  <LocationTypeInput label="Location Type" source="location_type_id" alwaysOn resettable />,
  <BooleanInput alwaysOn source="active" />,
  <DateInput alwaysOn label="Updated after" source="search.updated_at_from" />,
];

const StateLocationTypeRateSettingList = () => (
  <List filters={StateLocationTypeRateSettingListFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <LinkField label="ID" source="id" />
      <LinkField label="State" source="state.name" />
      <LinkField label="Location Type" source="location_type.label" />
      <BooleanField source="active" />
      <BooleanField
        label="Track Unpaid Breaks"
        source="settings.track_unpaid_breaks"
        sortable={false}
      />
      <DateTimeField format="MM/dd/yyyy HH:mm" source="updated_at" timeZone="America/Los_Angeles" />
    </Datagrid>
  </List>
);

StateLocationTypeRateSettingList.query = gql`
  query stateLocationTypeRateSettings($input: StateLocationTypeRateSettingQueryInput) {
    _stateLocationTypeRateSettingsMeta(input: $input) {
      count
    }
    stateLocationTypeRateSettings(input: $input) {
      id
      active
      settings
      state {
        id
        name
      }
      location_type {
        id
        label
      }
      state_id
      updated_at
    }
  }
`;

export default StateLocationTypeRateSettingList;
