interface RateSettingTaxes {
  original_charge_rate_settings?: number;
  charge_rate_cents?: number;
  ten99_per_diem: {
    charge_max_daily_regular_hours?: number;
  };
  w2: {
    charge_max_daily_regular_hours?: number;
  };
  use_blended_rate?: boolean;
}

export class RateSettingsDisplayHelper {
  constructor(private rateSetting: RateSettingTaxes) {}

  getActiveRate(): '8-hour-rate' | 'blended-rate' {
    return this.rateSetting.use_blended_rate ? 'blended-rate' : '8-hour-rate';
  }

  getChargeRateCents() {
    const activeRate = this.getActiveRate();
    if (activeRate === '8-hour-rate') {
      return this.rateSetting.original_charge_rate_settings;
    }

    return this.rateSetting.charge_rate_cents;
  }
}
