import * as React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  Pagination,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import LinkField from 'components/LinkField';

const FundingSourcesTab = () => {
  return (
    <SimpleShowLayout>
      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="FundingSource"
        target="personable_id"
        filter={{ personable_type: 'BillingGroup' }}
        sort={{ field: 'updated_at', order: 'DESC' }}
      >
        <Datagrid bulkActionButtons={false}>
          <LinkField label="ID" source="id" />
          <TextField label="Type" source="account_type" />
          <TextField label="Status" source="status" />
          <TextField label="Bank Name" source="bank_name" />
          <TextField label="Brand" source="brand" />
          <BooleanField label="Active" source="active" sortable={false} />
          <BooleanField
            label="Instant Pay Eligible"
            source="instant_pay_eligible"
            sortable={false}
          />
          <TextField label="Last 4" source="last4" />
          <TextField label="Exp Month" source="exp_month" />
          <TextField label="Exp Year" source="exp_year" />
          <DateField source="updated_at" label="Updated At" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(FundingSourcesTab);
