import React from 'react';
import { gql } from '@apollo/client';
import {
  NumberField,
  FunctionField,
  TopToolbar,
  EditButton,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  BooleanField,
  TabbedShowLayoutTabs,
  useRecordContext,
} from 'react-admin';
import getRelationshipsTab from 'components/tabs/RelationshipsTab';
import MoneyField from 'components/MoneyField';
import DateTimeField from 'components/DateTimeField';
import PayPositionRateSettingsTab from 'components/tabs/PayPositionRateSettingsTab';
import ChargePositionRateSettingsTab from 'components/tabs/ChargePositionRateSettingsTab';
import RefundFormButton from 'resources/refunds/RefundForm/RefundFormButton';
import LocationAside from './LocationAside';
import PositionsTab from './tabs/PositionsTab';
import RateSettingsTab from 'components/tabs/RateSettingsTab';
import TimestampFields from 'components/TimestampFields';
import MetabaseUrlField from 'components/MetabaseUrlField';
import LinkField from 'components/LinkField';
import { Link } from 'react-router-dom';
import EnumField from 'components/Enum/EnumField';
import useCheckAccess from 'hooks/useCheckAccess';
import BillingGroupsTab from './tabs/BillingGroupsTab';
import JobsTab from './tabs/JobsTab';
import AccountsTab from './tabs/AccountsTab';
import AssignmentsTab from './tabs/AssignmentsTab';
import NotesTab from 'components/tabs/NotesTab';
import RequirementsTab from './tabs/RequirementsTab';
import DisputesTab from 'components/tabs/DisputesTab';
import AgenciesTab from './tabs/AgenciesTab';
import MigrateLocationButton from './migrate_location/MigrateLocationButton';
import CustomRates from './CustomRates';

const RelationshipsTab = getRelationshipsTab('Location', 'Professional');
const SNF_LOCATION_ID = 7;

const LocationActions = () => {
  const record = useRecordContext();
  const couldBeRefunded = record?.balance_cents > 0;
  const isLocationSnf = record?.location_type_id === SNF_LOCATION_ID;
  const hasLprs = !!record?.location_position_rate_settings?.length;
  const showCreateCustomLprs = isLocationSnf && !hasLprs;

  return (
    <TopToolbar>
      {showCreateCustomLprs && <CustomRates />}
      {couldBeRefunded && <RefundFormButton billingGroupId={record.id as number} mr={2} />}
      <MigrateLocationButton />
      <EditButton />
    </TopToolbar>
  );
};

const LocationsShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show actions={<LocationActions />} aside={<LocationAside />} data-testid="locations-show">
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="info">
          <TextField label="ID" source="id" />
          <LinkField label="Company" source="company_id" />
          <TextField label="Name" source="name" />
          <TextField label="Unit" source="unit" />
          <TextField label="Location Type" source="location_type.label" />
          <TextField label="Status" source="status" />
          <TextField label="Phone Number" source="phone_number" />
          <TextField label="About" source="about" />
          <NumberField source="mileage_requirement" />
          <NumberField label="Minimum Years Of Experience" source="min_years_of_experience" />
          <NumberField
            label="Latitude"
            options={{ maximumFractionDigits: 10 }}
            source="coordinates.latitude"
          />
          <NumberField
            label="Longitude"
            options={{ maximumFractionDigits: 10 }}
            source="coordinates.longitude"
          />
          <TextField label="Address First" source="address_first" />
          <TextField label="Address Second" source="address_second" />
          <TextField label="City" source="address_city" />
          <TextField label="Zip" source="address_zip" />
          <TextField label="State" source="state.name" />
          <TextField label="Market" source="market.display_name" />
          <NumberField
            label="Non-Billable Orientation Burden Hours"
            source="non_billable_orientation_burden_hours"
          />
          <TextField label="Parking Instructions" source="parking_instructions" />
          <BooleanField label="Parking Free" source="parking_free" />
          <BooleanField label="Parking Reimbursed" source="parking_reimbursed" />
          <BooleanField label="Parking Validated" source="parking_validated" />
          <MoneyField label="Parking Cost" source="parking_cost_cents" />
          <MoneyField label="Parking Reimbursement" source="parking_reimbursement_cents" />
          <FunctionField
            label="Account Manager"
            render={(r) =>
              r.account_manager &&
              r.account_manager.account && (
                <Link to={`/Administrator/${r.account_manager_id}/show`}>
                  {r.account_manager.account.name}
                </Link>
              )
            }
          />
          <FunctionField
            label="Clinical Account Manager"
            render={(r) =>
              r.clinical_account_manager &&
              r.clinical_account_manager.account && (
                <Link to={`/Administrator/${r.clinical_account_manager_id}/show`}>
                  {r.clinical_account_manager.account.name}
                </Link>
              )
            }
          />
          <FunctionField
            label="Sales Associate"
            render={(r) =>
              r.sales_associate &&
              r.sales_associate.account && (
                <Link to={`/Administrator/${r.sales_associate_id}/show`}>
                  {r.sales_associate.account.name}
                </Link>
              )
            }
          />
          <DateTimeField
            format="MM/dd/yyyy HH:mm"
            tzSource="timezone_lookup"
            label="Last Time Job Posted"
            source="last_time_job_posted"
          />
          <TextField label="Detailed Notes" source="detailed_notes" />
          <BooleanField label="Scrubs Provided" source="scrubs_provided" />
          <BooleanField label="Drug Screening Required" source="drug_screening_required" />
          <EnumField emptyText="none" label="Covid Requirement" source="covid_requirement" />
          <TextField label="Timezone Lookup" source="timezone_lookup" />
          <BooleanField label="Allow Per Diem Jobs" source="allow_per_diem_jobs" />
          <BooleanField label="Allow Assignments" source="allow_assignments" />
          <NumberField
            label="Charge overtime multiplier"
            source="main_rate_setting.charge_overtime_multiplier"
          />
          <TextField
            label="Charge overtime frequency"
            source="main_rate_setting.charge_overtime_frequency"
          />
          <NumberField label="Cancellation Fee Window" source="cancellation_fee_window" />
          <NumberField
            label="Cancellation Fee Charge Hours"
            source="cancellation_fee_charge_hours"
          />
          <NumberField label="Guaranteed Minimum Hours" source="guaranteed_minimum_hours" />
          <NumberField
            label="Start Clock In Early Minutes"
            source="start_clock_in_early_window_minutes"
            defaultValue={0}
          />
          <NumberField
            label="Clock In Early Minutes"
            source="clock_in_early_window"
            defaultValue={0}
          />
          <NumberField
            label="Maximum Clock Out Period"
            source="maximum_late_clock_out_grace_period_minutes"
            defaultValue={0}
          />
          <NumberField
            label="Approval Free Grace Period"
            source="approval_free_late_clock_out_grace_period_minutes"
          />
          <NumberField label="Common Shift Length Hours" source="common_shift_length_hours" />
          <BooleanField
            label="Allow pro to adjust start/end times"
            source="professional_can_adjust_times"
          />
          <BooleanField source="enable_geo_fence" />
          <NumberField source="geo_fence_radius" defaultValue={0} />
          <BooleanField source="location_confirmation_job_irregularities_enabled" />
          {checkAccess('view', 'Audits') && <MetabaseUrlField />}
          <BooleanField label="Require 530 on Clock Out" source="require_upload_on_clock_out" />
          <BooleanField
            label="Require photo on clock in"
            source="require_facility_timecard_system_photo_on_clock_in"
          />
          <BooleanField
            label="Require photo on clock out"
            source="require_facility_timecard_system_photo_on_clock_out"
          />
          <BooleanField source="ignore_signature_when_creating_meal_break" />
          <BooleanField source="allow_weekly_overtime_settings" />
          <EnumField source="professional_weekly_overtime_settings" />
          <TextField label="External ID" source="external_id" />
          <BooleanField source="pro_pool_enabled" />
          <BooleanField label="Shift Confirmation Required" source="require_shift_confirmation" />
          {checkAccess('view', 'Location', 'facility_managed_agency_enabled') && (
            <BooleanField source="facility_managed_agency_enabled" />
          )}
          <TimestampFields />
        </Tab>

        <Tab label="Accounts" path="accounts">
          <AccountsTab />
        </Tab>

        {checkAccess('view', 'BillingGroup') && (
          <Tab label="Billing Groups" path="billing-groups">
            <BillingGroupsTab />
          </Tab>
        )}
        {checkAccess('view', 'AgencyLocation') && (
          <Tab label="Agencies" path="agency-locations">
            <AgenciesTab />
          </Tab>
        )}
        {checkAccess('view', 'Job') && (
          <Tab label="Jobs" path="jobs">
            <JobsTab />
          </Tab>
        )}
        {checkAccess('view', 'Assignment') && (
          <Tab label="Assignments" path="assignments">
            <AssignmentsTab />
          </Tab>
        )}
        {checkAccess('view', 'Position') && (
          <Tab label="positions" path="location_positions">
            <PositionsTab />
          </Tab>
        )}
        {checkAccess('view', 'Relationship') && (
          <Tab label="Relationships" path="relationships">
            <RelationshipsTab
              canCreate={checkAccess('create', 'Relationship')}
              canDelete={checkAccess('delete', 'Relationship')}
            />
          </Tab>
        )}
        {checkAccess('view', 'LocationRateSetting') && (
          <Tab label="Rate settings" path="rate-settings">
            <RateSettingsTab />
          </Tab>
        )}
        {checkAccess('view', 'LocationPositionRateSetting') && (
          <Tab label="Pay Position Rate settings" path="pay-position-rate-settings">
            <PayPositionRateSettingsTab />
          </Tab>
        )}
        {checkAccess('view', 'LocationPositionRateSetting') && (
          <Tab label="Charge Position Rate settings" path="charge-position-rate-settings">
            <ChargePositionRateSettingsTab />
          </Tab>
        )}
        {checkAccess('view', 'Note') && (
          <Tab label="Notes" path="notes">
            <NotesTab />
          </Tab>
        )}
        {checkAccess('view', 'Dispute') && (
          <Tab label="Disputes" path="disputes">
            <DisputesTab target="location_id" />
          </Tab>
        )}
        <Tab label="Requirements" path="requirements">
          <RequirementsTab
            locationRequirementsFilter={{ cohort_eligibility: ['medely_internal', 'both'] }}
          />
        </Tab>
        <Tab label="SaaS Requirements" path="saas-requirements">
          <RequirementsTab
            locationRequirementsLabel="SaaS Location Requirements"
            locationRequirementsFilter={{ cohort_eligibility: ['saas', 'both'] }}
            showCompanyRequirements={false}
            showStateRequirements={false}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
LocationsShow.query = gql`
  query location($id: Int!) {
    location(id: $id) {
      id
      company_id
      company {
        saas_scheduling_enabled
      }
      name
      location_type {
        id
        label
      }
      status
      approval_free_late_clock_out_grace_period_minutes
      phone_number
      about
      mileage_requirement
      min_years_of_experience
      coordinates {
        latitude
        longitude
      }
      address_first
      address_second
      address_city
      address_zip
      state {
        id
        name
      }
      market {
        id
        display_name
      }
      billing_groups {
        id
      }
      non_billable_orientation_burden_hours
      parking_instructions
      parking_free
      parking_reimbursed
      parking_validated
      parking_cost_cents
      parking_reimbursement_cents
      account_manager_id
      account_manager {
        id
        account {
          id
          name
          first_name
          last_name
        }
      }
      clinical_account_manager_id
      clinical_account_manager {
        id
        account {
          id
          name
          first_name
          last_name
        }
      }
      sales_associate_id
      sales_associate {
        id
        account {
          id
          name
          first_name
          last_name
        }
      }
      last_time_job_posted
      location_position_rate_settings {
        id
      }
      location_rate_settings {
        id
        settings
      }
      positions {
        id
        display_name
      }
      timezone_lookup
      detailed_notes
      scrubs_provided
      drug_screening_required
      covid_requirement
      allow_per_diem_jobs
      allow_assignments
      cancellation_fee_window
      cancellation_fee_charge_hours
      guaranteed_minimum_hours
      start_clock_in_early_window_minutes
      clock_in_early_window
      maximum_late_clock_out_grace_period_minutes
      professional_can_adjust_times
      enable_geo_fence
      geo_fence_radius
      require_upload_on_clock_out
      require_facility_timecard_system_photo_on_clock_in
      require_facility_timecard_system_photo_on_clock_out
      ignore_signature_when_creating_meal_break
      created_at
      updated_at
      allow_reserved_for_favorites
      allow_weekly_overtime_settings
      professional_weekly_overtime_settings
      external_id
      unit
      common_shift_length_hours
      location_confirmation_job_irregularities_enabled
      pro_pool_enabled
      require_shift_confirmation
      facility_managed_agency_enabled
    }
  }
`;
export default LocationsShow;
