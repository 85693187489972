import React from 'react';
import {
  Datagrid,
  TextField,
  FunctionField,
  NumberField,
  BooleanInput,
  useResourceContext,
  useRecordContext,
  SimpleShowLayout,
  BooleanField,
} from 'react-admin';
import LinkField from 'components/LinkField';
import { Button } from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import MoneyField from 'components/MoneyField';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import PercentField from 'components/PercentField';
import { buildCustomExporter } from 'resources/rate_settings/buildCustomExporter';
import { DisplayHelpers, camelToSnakeCase } from 'utils/helpers';
import List from 'components/List';
import { Box } from '@mui/system';
import { RateSettingsDisplayHelper } from 'utils/displayHelpers/rate-settings';

const Filters = () => {
  const record = useRecordContext();
  return [
    <PositionInput
      alwaysOn
      source="search.position_ids"
      multiple
      clearable
      filter={{ search: { position_ids: record.position_ids } }}
    />,
    <SpecialtyInput
      alwaysOn
      multiple
      clearable
      source="search.specialty_ids"
      positionSource="search.position_ids"
      optionText="label"
    />,
    <BooleanInput alwaysOn label="Valid For Jobs" source="for_job" />,
    <BooleanInput alwaysOn label="Valid For Assignments" source="for_assignment" />,
  ];
};

const getExporter = (filenamePrefix) => (rateSettings) => {
  if (!rateSettings || rateSettings.length === 0) {
    return true;
  }

  return buildCustomExporter(rateSettings, filenamePrefix);
};

const CreatePositionRateSettingButton = () => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const recordId = record[`${camelToSnakeCase(resource)}_id`];
  const to = recordId ? `/${resource}/${recordId}/edit` : `/${resource}/create`;
  let display;
  if (record.location_position_rate_setting_id && record.location_id) {
    display = 'Update';
  } else if (record.market_position_rate_setting_id && record.market_id) {
    display = 'Update';
  } else if (record.state_position_rate_setting_id && record.state_id) {
    display = 'Update';
  } else {
    display = 'Create Custom';
  }
  return (
    <FunctionField
      render={() => (
        <Button component={Link} size="small" color="primary" to={to} state={{ record }}>
          {display}
        </Button>
      )}
    />
  );
};

const ChargePositionRateSettingsTab = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const parentIdName = `${camelToSnakeCase(resource)}_id`;
  const resourceName = `${resource}PositionRateSetting`;
  const exporter = getExporter(`${record.__typename} ${record.id}`);

  return (
    <SimpleShowLayout>
      <Box display="flex">
        <Button
          component={Link}
          color="primary"
          to={`/${resourceName}/create`}
          state={{ record: { [parentIdName]: record.id } }}
        >
          Add position rate settings
        </Button>
      </Box>
      <List
        resource={resourceName}
        filters={Filters()}
        hasCreate={false}
        filter={{
          [parentIdName]: record.id,
          include_parents: true,
          position_ids: record.position_ids,
        }}
        exporter={exporter}
        disableSyncWithLocation
      >
        <Datagrid bulkActionButtons={false}>
          <CreatePositionRateSettingButton />
          <FunctionField
            label="Active for Job"
            render={(v) => (v?.settings.can_post_job ? <Check /> : <Clear />)}
          />
          <FunctionField
            label="Active for Assignment"
            render={(v) => (v?.settings.can_post_assignment ? <Check /> : <Clear />)}
          />
          <LinkField source={parentIdName} />
          <TextField label="Position" source="position.display_name" />
          <TextField label="Specialty" source="specialty.label" />
          {resource === 'Market' && <TextField label="Market" source="market.display_name" />}
          {resource === 'Location' && (
            <FunctionField
              label="Active charge rate"
              render={(record) => {
                const rateSettingsDisplayHelper = new RateSettingsDisplayHelper(record.settings);
                let activeChargeRate = '8h';

                try {
                  const activeRate = rateSettingsDisplayHelper.getActiveRate(
                    record.primary_billing_group?.plan,
                  );

                  if (activeRate === 'blended-rate') {
                    activeChargeRate = 'Blended';
                  }
                } catch (e) {}

                return activeChargeRate;
              }}
            />
          )}
          <MoneyField label="Charge 8h rate" source="settings.original_charge_rate_cents" />
          <MoneyField label="Charge 12h rate" source="settings.blended_rate_cents" />
          <MoneyField
            label="Charge On Call Rate"
            source="settings.charge_on_call_hourly_rate_cents"
          />
          <MoneyField label="Agency Charge Rate" source="settings.agency_charge_rate_cents" />
          <NumberField
            label="1099 Charge Extended Rate"
            source="settings.ten99_per_diem.charge_overtime_multiplier"
          />
          <NumberField
            label="1099 Charge Second Extended Rate"
            source="settings.ten99_per_diem.charge_double_overtime_multiplier"
          />
          <NumberField
            label="1099 Charge Extended Rate Starts At"
            source="settings.ten99_per_diem.charge_max_daily_regular_hours"
          />
          <FunctionField
            label="1099 Charge Second Extended Rate Starts At"
            render={({ settings }) =>
              DisplayHelpers.roundToTwoDecimals(
                settings.ten99_per_diem.charge_max_daily_regular_hours +
                  settings.ten99_per_diem.charge_max_daily_overtime_hours,
              )
            }
          />
          <NumberField
            label="1099 Charge Weekly Extended Rate Starts At"
            source="settings.ten99_per_diem.charge_max_weekly_regular_hours"
          />
          <BooleanField
            label="1099 Charge Seventh Day Rule Enabled"
            source="settings.ten99_per_diem.charge_seventh_day_rule_enabled"
          />
          <PercentField label="On Call Fee Percent" source="settings.on_call_fee_percent" />
          <PercentField label="Margin 1099 Percent" source="settings.margin_percent_1099" />
          <PercentField label="Margin W2 Local Percent" source="settings.margin_percent_w2_local" />
          <PercentField
            label="Margin W2 Travel Percent"
            source="settings.margin_percent_w2_travel"
          />
          <PercentField label="Margin W2 TCV Percent" source="settings.margin_percent_w2_tcv" />
          <MoneyField label="Margin W2 TCV" source="settings.margin_w2_tcv_cents" />
          <TextField label="Parent" source="id" />
        </Datagrid>
      </List>
    </SimpleShowLayout>
  );
};

export default ChargePositionRateSettingsTab;
