import React, { useEffect, useRef, useState } from 'react';
import MoneyInput from 'components/MoneyInput';
import { calcPayoutRate, calc1099Margin } from 'resources/rate_settings/rateSettingsCalculations';
import { useFormContext } from 'react-hook-form';
import { mergeDeepWith } from 'ramda';
import { BooleanInput, NumberInput, useRecordContext, useResourceContext } from 'react-admin';
import { Alert, Box } from '@medely/ui-kit/web';

type Props = {
  source: string;
  inheritedSettings: any;
};

const getSource = (sourceKey, source) => {
  if (sourceKey) {
    return `${sourceKey}.${source}`;
  }
  return source;
};

function RatesField({ label, settingsField, field }) {
  const { setValue, watch } = useFormContext();
  const values = watch();
  const isFocusing = useRef(null);

  const InputComponent = field === 'blended_rate_multiplier' ? NumberInput : MoneyInput;
  const source = `${settingsField}.${field}`;
  const inputValue = values[settingsField]?.[field];
  const chargeRateCentsValue = values[settingsField]?.charge_rate_cents / 100;
  const blendedRateCentsField = 'blended_rate_cents';
  const blendedRateMultiplierField = 'blended_rate_multiplier';

  function formatNumber(num) {
    return Number.isInteger(num) ? String(num) : num.toFixed(4);
  }

  useEffect(() => {
    if (!inputValue) {
      return;
    }

    if (
      field === blendedRateCentsField &&
      chargeRateCentsValue &&
      isFocusing.current === blendedRateCentsField
    ) {
      const formattedValue = inputValue / 100;

      setValue(
        `${settingsField}.blended_rate_multiplier`,
        formatNumber(formattedValue / chargeRateCentsValue),
      );

      return;
    }

    if (
      field === blendedRateMultiplierField &&
      chargeRateCentsValue &&
      isFocusing.current === blendedRateMultiplierField
    ) {
      const newBlendedRateCents = chargeRateCentsValue * 100 * inputValue;
      setValue(`${settingsField}.blended_rate_cents`, newBlendedRateCents);

      return;
    }
  }, [inputValue]);

  return (
    <>
      <InputComponent
        value={inputValue}
        label={label}
        source={source}
        onChange={(e) => setValue(source, e.target.value)}
        onFocus={() => {
          isFocusing.current = field;
        }}
        onBlur={() => {
          isFocusing.current = null;
        }}
      />
      {field === blendedRateMultiplierField && (
        <Box maxWidth="80%" mb={4}>
          <Alert
            title="12 hour rate multiplier"
            description="When entering values, please note that the 12h rate markup may be applied to inherited values."
            variant="filled"
            hideCloseIcon
          />
        </Box>
      )}
    </>
  );
}

const LocationAndProfessionalRateInput = ({ source, inheritedSettings = {} }: Props) => {
  const [showBlendedRateAlert, setShowBlendedRateAlert] = useState(false);
  const context = useRecordContext();
  const { watch, setValue } = useFormContext();
  const formData = watch(source) ?? {};
  const defaultValue = useRef(null);
  const isCreate = !context;
  const resource = useResourceContext();
  const showUseBlendedRate = resource === 'LocationPositionRateSetting';
  const show12hRateCents =
    resource === 'LocationPositionRateSetting' || resource === 'MarketPositionRateSetting';

  const mergedSettings = mergeDeepWith((a, b) => a ?? b, formData, inheritedSettings);
  const perDiemSettings = mergedSettings.ten99_per_diem ?? {};
  const chargeRateCents =
    perDiemSettings.charge_max_daily_regular_hours === 8
      ? mergedSettings.original_charge_rate_cents
      : mergedSettings.charge_rate_cents;

  useEffect(() => {
    const value = calcPayoutRate(
      chargeRateCents,
      mergedSettings.margin_percent_1099,
      perDiemSettings.charge_max_daily_regular_hours,
      perDiemSettings.charge_max_daily_overtime_hours,
      perDiemSettings.charge_overtime_multiplier,
      perDiemSettings.payout_max_daily_regular_hours,
      perDiemSettings.payout_max_daily_overtime_hours,
      perDiemSettings.payout_overtime_multiplier,
    );
    if (value > 0) {
      // When useEffect is called on load, pro_rate_cents isn't registered with the form.
      // So we use defaultValue to prefill the field.
      defaultValue.current = value;
      setValue(getSource(source, 'pro_rate_cents'), value);
    }
  }, [
    setValue,
    source,
    chargeRateCents,
    mergedSettings.margin_percent_1099,
    perDiemSettings.charge_max_daily_regular_hours,
    perDiemSettings.charge_max_daily_overtime_hours,
    perDiemSettings.charge_overtime_multiplier,
    perDiemSettings.payout_max_daily_regular_hours,
    perDiemSettings.payout_max_daily_overtime_hours,
    perDiemSettings.payout_overtime_multiplier,
  ]);

  const onProRateBlur = () => {
    const value = calc1099Margin(
      chargeRateCents,
      mergedSettings.pro_rate_cents,
      perDiemSettings.charge_max_daily_regular_hours,
      perDiemSettings.charge_max_daily_overtime_hours,
      perDiemSettings.charge_overtime_multiplier,
      perDiemSettings.payout_max_daily_regular_hours,
      perDiemSettings.payout_max_daily_overtime_hours,
      perDiemSettings.payout_overtime_multiplier,
    );
    if (value > 0) {
      setValue(getSource(source, 'margin_percent_1099'), value);
    }
  };

  return (
    <>
      <MoneyInput
        label="Professional Rate"
        defaultValue={defaultValue.current}
        source={getSource(source, 'pro_rate_cents')}
        helperText={isCreate ? 'Auto calculation needs 1099 settings filled' : null}
        onBlur={onProRateBlur}
      />

      {showUseBlendedRate && (
        <BooleanInput
          label="Use 12h location rate structure"
          source={`${source}.use_blended_rate`}
          onClick={() => setShowBlendedRateAlert(true)}
        />
      )}

      {showBlendedRateAlert && (
        <Alert
          title="12 hour rate structure"
          description="Please set the max hour fields appropriately based on the OT/XR policy that needs to be applied to the location and position."
          variant="filled"
          hideCloseIcon
        />
      )}

      <RatesField settingsField={source} label="Location Rate" field="charge_rate_cents" />

      {show12hRateCents && (
        <RatesField label="12 hour rate" settingsField={source} field="blended_rate_cents" />
      )}

      <RatesField
        label="12 hour rate multiplier"
        settingsField={source}
        field={'blended_rate_multiplier'}
      />
    </>
  );
};

export default LocationAndProfessionalRateInput;
