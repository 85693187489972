import React from 'react';
import {
  TextField,
  BooleanField,
  NumberField,
  SimpleShowLayout,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import TimestampFields from 'components/TimestampFields';
import W2And1099SubSettingsShow from './W2And1099SubSettingsShow';
import LinkField from 'components/LinkField';
import { RateSettingsDisplayHelper } from 'utils/displayHelpers/rate-settings';

const activeRateLabel = '(Active)';

const RateSettingsShow = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const resource = useResourceContext();
  const { user_id, market_id, state_id, location_id } = record;
  const isLocationLevel = resource === 'LocationRateSetting';

  function shouldPutActiveLabel(rate: '8-hour-rate' | 'blended-rate') {
    if (!isLocationLevel) {
      return false;
    }

    const rateSettingsDisplayHelper = new RateSettingsDisplayHelper(record.settings);

    try {
      const activeRate = rateSettingsDisplayHelper.getActiveRate();

      return activeRate === rate;
    } catch {
      return false;
    }
  }

  return (
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      {market_id && <TextField source="market_id" />}
      {location_id && <LinkField label="Location ID" source="location_id" />}
      {user_id && <TextField source="user_id" />}
      {state_id && <TextField source="state_id" />}
      <BooleanField source="active" />
      <BooleanField label="Track Unpaid Breaks" source="settings.track_unpaid_breaks" />
      <MoneyField label="RN Minimum Wage" source="settings.rn_minimum_wage_cents" />
      <MoneyField label="LVN Minimum Wage" source="settings.lvn_minimum_wage_cents" />
      <MoneyField label="Allied Minimum Wage" source="settings.allied_minimum_wage_cents" />
      {resource === 'StateRateSetting' && (
        <MoneyField label="W2 Minimum Wage" source="settings.w2_minimum_wage_cents" />
      )}
      <NumberField
        label="Billable Orientation Burden Hours"
        source="settings.billable_orientation_hours"
      />
      <NumberField
        label="Non Billable Orientation Burden Hours"
        source="settings.non_billable_orientation_burden_hours"
      />
      <NumberField label="Cancellation Fee Window" source="settings.cancellation_fee_window" />
      <NumberField
        label="Cancellation Fee Charge Hours"
        source="settings.cancellation_fee_charge_hours"
      />
      <NumberField
        label="Social Security Burden Multiplier"
        source="settings.social_security_burden_multiplier"
      />
      <NumberField
        label="Medicare Burden Multiplier"
        source="settings.medicare_burden_multiplier"
      />
      <MoneyField
        label="Federal Unemployment Max Burden"
        source="settings.federal_unemployment_max_burden_cents"
      />
      <NumberField
        label="Federal Unemployment Percent Burden Multiplier"
        source="settings.federal_unemployment_percent_burden_multiplier"
      />
      <MoneyField
        label="State Unemployment Max Burden"
        source="settings.state_unemployment_max_burden_cents"
      />
      <NumberField
        label="State Unemployment Percent Burden Multiplier"
        source="settings.state_unemployment_percent_burden_multiplier"
      />
      <MoneyField label="Compliance Burden" source="settings.compliance_burden_cents" />
      <NumberField label="Agency VMS Fee Percent" source="settings.agency_vms_fee_percent" />

      {isLocationLevel && (
        <BooleanField label="Using 12h location rate structure" source="settings.use_blended_rate" />
      )}

      <MoneyField
        label={`8 hour rate ${shouldPutActiveLabel('8-hour-rate') ? activeRateLabel : ''}`}
        source="settings.original_charge_rate_cents"
      />
      <MoneyField
        label={`12 hour rate ${shouldPutActiveLabel('blended-rate') ? activeRateLabel : ''}`}
        source="settings.blended_rate_cents"
      />

      <NumberField label="12 hour rate multiplier" source="settings.blended_rate_multiplier" />

      <TimestampFields />
      <W2And1099SubSettingsShow source="settings" />
    </SimpleShowLayout>
  );
};

export default RateSettingsShow;
