import React from 'react';
import { FunctionField, useRecordContext } from 'react-admin';
import { DisplayHelpers } from 'utils/helpers';
import { calcPayoutRate } from 'resources/rate_settings/rateSettingsCalculations';

const ProRateField = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  const { settings } = record;
  if (settings.charge_rate_cents && settings.margin_percent_1099 && settings.ten99_per_diem) {
    const chargeMaxDailyRegularHours = settings.ten99_per_diem.charge_max_daily_regular_hours;

    const chargeRate =
      chargeMaxDailyRegularHours > 8
        ? settings.charge_rate_cents
        : settings.original_charge_rate_cents;

    return (
      <FunctionField
        render={() =>
          DisplayHelpers.money(
            calcPayoutRate(
              chargeRate,
              settings.margin_percent_1099,
              chargeMaxDailyRegularHours,
              settings.ten99_per_diem.charge_max_daily_overtime_hours,
              settings.ten99_per_diem.charge_overtime_multiplier,
              settings.ten99_per_diem.payout_max_daily_regular_hours,
              settings.ten99_per_diem.payout_max_daily_overtime_hours,
              settings.ten99_per_diem.payout_overtime_multiplier,
            ),
          )
        }
      />
    );
  }
  return null;
};

ProRateField.defaultProps = {
  label: 'Professional Rate',
};

export default ProRateField;
